import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivationEnd,
  ParamMap,
  Router,
} from '@angular/router';
import { LocalStorageService } from '../../services/ls-service';

const HOME_ICON = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2386 2.78245C12.9876 1.68942 11.0124 1.68942 9.76139 2.78245L3.11313 8.59111C2.95024 8.73343 2.84067 8.91882 2.79995 9.121C2.00366 13.075 1.94488 17.1228 2.62609 21.0946L2.78778 22.0373H7.06499V13.9101C7.06499 13.3681 7.54752 12.9287 8.14275 12.9287H15.8572C16.4525 12.9287 16.935 13.3681 16.935 13.9101V22.0373H21.2122L21.3739 21.0946C22.0551 17.1228 21.9963 13.075 21.2 9.121C21.1593 8.91882 21.0498 8.73343 20.8869 8.59111L14.2386 2.78245ZM8.26898 1.36629C10.354 -0.455429 13.646 -0.455429 15.731 1.36629L22.3793 7.17495C22.8687 7.60254 23.1979 8.15954 23.3202 8.767C24.1602 12.9379 24.2222 17.2078 23.5036 21.3974L23.2439 22.9119C23.1364 23.5382 22.544 24 21.8478 24H15.8572C15.262 24 14.7795 23.5606 14.7795 23.0187V14.8914H9.22051V23.0187C9.22051 23.5606 8.73798 24 8.14275 24H2.15223C1.45602 24 0.86356 23.5382 0.756136 22.9119L0.496378 21.3974C-0.222198 17.2078 -0.160195 12.9379 0.679783 8.767C0.80212 8.15954 1.13132 7.60254 1.62072 7.17494L8.26898 1.36629Z" fill="currentColor"/>
</svg>`;

const TERM_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M5.25 0C5.66421 0 6 0.335786 6 0.75V1.5H18V0.75C18 0.335786 18.3358 0 18.75 0C19.1642 0 19.5 0.335786 19.5 0.75V1.5H21C22.6569 1.5 24 2.84315 24 4.5V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V4.5C0 2.84315 1.34315 1.5 3 1.5H4.5V0.75C4.5 0.335786 4.83579 0 5.25 0ZM3 3C2.17157 3 1.5 3.67157 1.5 4.5V21C1.5 21.8284 2.17157 22.5 3 22.5H21C21.8284 22.5 22.5 21.8284 22.5 21V4.5C22.5 3.67157 21.8284 3 21 3H3Z" fill="#1C1B1F"/>
<path d="M3.75 6C3.75 5.58579 4.08579 5.25 4.5 5.25H19.5C19.9142 5.25 20.25 5.58579 20.25 6V7.5C20.25 7.91421 19.9142 8.25 19.5 8.25H4.5C4.08579 8.25 3.75 7.91421 3.75 7.5V6Z" fill="#1C1B1F"/>
<path d="M13.5 12C13.5 11.1716 14.1716 10.5 15 10.5H22.5V13.5H15C14.1716 13.5 13.5 12.8284 13.5 12Z" fill="#1C1B1F"/>
<path d="M1.5 15H7.5C8.32843 15 9 15.6716 9 16.5C9 17.3284 8.32843 18 7.5 18H1.5V15Z" fill="#1C1B1F"/>
</svg>`;

const LESSON_PLAN_ICON = `
<svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Icon">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9082 20.2803C13.0678 20.376 13.2384 20.4184 13.4042 20.4163C13.5699 20.4184 13.7405 20.376 13.9001 20.2803L15.3156 19.4309C18.0918 17.7652 21.4872 17.4932 24.4932 18.6956C25.6021 19.1391 26.8083 18.3225 26.8083 17.1281V2.59071C26.8083 1.87006 26.3696 1.22202 25.7005 0.95438C21.8287 -0.594311 17.4556 -0.243905 13.8799 1.90153L13.4042 2.18697L12.9284 1.90153C9.3527 -0.243905 4.97958 -0.594311 1.10785 0.95438C0.438746 1.22202 0 1.87006 0 2.59071V17.1281C0 18.3225 1.20624 19.1391 2.31514 18.6956C5.32116 17.4932 8.71647 17.7652 11.4927 19.4309L12.9082 20.2803ZM1.87035 2.66406V16.8622C5.35641 15.5554 9.25422 15.9067 12.455 17.8271L12.469 17.8355V3.80705L11.9661 3.50534C8.91251 1.67316 5.18253 1.36351 1.87035 2.66406ZM14.3534 17.8271L14.3393 17.8355V3.80705L14.8422 3.50534C17.8958 1.67316 21.6258 1.36351 24.938 2.66406V16.8622C21.4519 15.5554 17.5541 15.9067 14.3534 17.8271Z" fill="#1C1B1F"/>
<path d="M10.0066 20.7868C7.48301 19.3147 4.36252 19.3147 1.83897 20.7868L1.71087 20.8615C1.26474 21.1218 1.11405 21.6944 1.37429 22.1405C1.63453 22.5866 2.20715 22.7373 2.65328 22.4771L2.78139 22.4024C4.72258 21.27 7.12296 21.27 9.06414 22.4024L10.4198 23.1932C12.264 24.2689 14.5443 24.2689 16.3885 23.1932L17.7442 22.4024C19.6854 21.27 22.0857 21.27 24.0269 22.4024L24.155 22.4771C24.6012 22.7373 25.1738 22.5866 25.434 22.1405C25.6943 21.6944 25.5436 21.1218 25.0974 20.8615L24.9693 20.7868C22.4458 19.3147 19.3253 19.3147 16.8018 20.7868L15.4461 21.5776C14.1843 22.3137 12.624 22.3137 11.3623 21.5776L10.0066 20.7868Z" fill="#1C1B1F"/>
</g>
</svg>
`;

const LESSON_INFO_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M6.87709e-06 0.75C6.87709e-06 0.335786 0.335793 0 0.750007 0H8.25001C8.66422 0 9.00001 0.335786 9.00001 0.75V8.66547L15.2207 2.46865C15.5137 2.17672 15.9878 2.17717 16.2803 2.46967L21.5836 7.77299C21.7244 7.91382 21.8035 8.10488 21.8033 8.30404C21.8031 8.5032 21.7237 8.69411 21.5826 8.83467L15.3935 15H23.25C23.6642 15 24 15.3358 24 15.75V23.25C24 23.6642 23.6642 24 23.25 24H4.50001C3.25737 24 2.13236 23.4963 1.31802 22.682C0.884578 22.2485 0.558043 21.7487 0.338413 21.2152C0.315547 21.1597 0.293756 21.1038 0.273066 21.0473C0.103609 20.5844 0.00799304 20.086 0.000485551 19.5663M9.00001 19.2518L19.9916 8.3023L15.749 4.05964L9.00001 10.7827V19.2518ZM6.75001 19.5C6.75001 18.2574 5.74265 17.25 4.50001 17.25C3.25737 17.25 2.25001 18.2574 2.25001 19.5C2.25001 20.7426 3.25737 21.75 4.50001 21.75C5.74265 21.75 6.75001 20.7426 6.75001 19.5ZM22.5 22.5V16.5H13.8877L7.86466 22.5H22.5Z" fill="#1C1B1F"/>
<path d="M6.87709e-06 0.75V19.4921V0.75Z" fill="#1C1B1F"/>
<path d="M6.87709e-06 19.4921V19.5C6.87709e-06 19.5221 0.000167423 19.5443 0.000485551 19.5663C0.000123886 19.5416 -3.62978e-05 19.5168 6.87709e-06 19.4921Z" fill="#1C1B1F"/>
</svg>`;

const PROFILE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12.001 12C15.3147 12 18.001 9.31371 18.001 6C18.001 2.68629 15.3147 0 12.001 0C8.68727 0 6.00098 2.68629 6.00098 6C6.00098 9.31371 8.68727 12 12.001 12ZM16.001 6C16.001 8.20914 14.2101 10 12.001 10C9.79184 10 8.00098 8.20914 8.00098 6C8.00098 3.79086 9.79184 2 12.001 2C14.2101 2 16.001 3.79086 16.001 6Z" fill="black"/>
<path d="M24.001 22C24.001 24 22.001 24 22.001 24H2.00098C2.00098 24 0.000976562 24 0.000976562 22C0.000976562 20 2.00098 14 12.001 14C22.001 14 24.001 20 24.001 22ZM22.0009 21.993C21.9981 21.4995 21.6934 20.0209 20.3367 18.6642C19.0322 17.3597 16.5792 16 12.001 16C7.42274 16 4.96968 17.3597 3.66517 18.6642C2.30849 20.0209 2.00383 21.4995 2.00098 21.993H22.0009Z" fill="black"/>
</svg>`;

const LIBRARY_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12.001 0L21.9158 4.5H23.251C23.6652 4.5 24.001 4.83579 24.001 5.25L24.001 8.25C24.001 8.66421 23.6652 9 23.251 9H22.501L22.501 19.5C22.8452 19.5 23.1452 19.7361 23.2286 20.07L23.9786 23.07C24.0347 23.2941 23.9843 23.533 23.8422 23.715C23.7001 23.897 23.482 24 23.251 24H0.75103C0.520078 24 0.302006 23.897 0.159875 23.715C0.0177451 23.533 -0.0325914 23.2941 0.0234226 23.07L0.773423 20.07C0.856892 19.7361 1.15688 19.5 1.50103 19.5L1.50098 9H0.750977C0.336763 9 0.000976562 8.66421 0.000976562 8.25L0.00102949 5.25C0.00102949 4.83579 0.336816 4.5 0.75103 4.5H2.0863L12.001 0ZM5.66576 4.5H18.3363L12.001 1.5L5.66576 4.5ZM3.00098 9L3.00103 19.5H4.50103L4.50098 9H3.00098ZM6.00098 9L6.00103 19.5H9.75103L9.75098 9H6.00098ZM11.251 9L11.251 19.5H12.751L12.751 9H11.251ZM14.251 9L14.251 19.5H18.001L18.001 9H14.251ZM19.501 9L19.501 19.5H21.001V9H19.501ZM22.501 7.5V6H1.50103V7.5H22.501ZM21.9154 21H2.08661L1.71161 22.5H22.2904L21.9154 21Z" fill="#1C1B1F"/>
</svg>`;

interface Breadcrumb {
  label: string;
  url: string;
}

interface Breadcrumbs {
  [key: string]: Breadcrumb[];
}
interface CurretBreadCrumbData {
  mode?: string;
  step?: number;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  current: CurretBreadCrumbData = {
    mode: undefined,
    step: undefined,
  };

  id: string = '';
  schedId: string = '';
  planId: string = '';

  newlyCreated = false;

  breadcrumbs: Breadcrumb[] = [];

  frontEndNames = this.ls.getFrontEndNames();

  default_breadcrumbs: Breadcrumbs = {
    new_lesson: [
      {
        label: `Add ${this.frontEndNames.lesson.toLowerCase()} information`,
        url: '/lesson-planner/lesson/add',
      },
      {
        label: `Add ${this.frontEndNames.unit.toLowerCase()}`,
        url: '/',
      },
      { label: 'Set up your plan', url: '/' },
    ],
    existing_lesson: [
      {
        label: `${this.frontEndNames.lesson} information`,
        url: '/lesson-planner/lesson/:id/edit',
      },
      {
        label: `Add ${this.frontEndNames.unit.toLowerCase()}`,
        url: '/lesson-planner/lesson/unit-planner/:id',
      },
      {
        label: 'Choose a term',
        url: '/lesson-planner/lesson/unit-planner/:id/plan-now',
      },
      {
        label: 'Set up your plan',
        url: '/lesson-planner/lesson/unit-planner/:id/plan-now/:schedId',
      },
    ],
    new_term: [
      { label: 'Add term information', url: '/lesson-planner/schedule/add' },
      {
        label: `Add ${this.frontEndNames.lesson.toLowerCase()}`,
        url: '/',
      },
      { label: 'Set up your plan', url: '/' },
    ],
    existing_term: [
      {
        label: 'Term information',
        url: '/lesson-planner/schedule/:schedId/edit',
      },
      {
        label: `Add ${this.frontEndNames.lesson.toLowerCase()}`,
        url: '/lesson-planner/schedule/:schedId/choose-lesson',
      },
      {
        label: 'Set up your plan',
        url: '/lesson-planner/schedule/:schedId/unit-planner/:id',
      },
    ],
    plan: [
      {
        label: 'Update your plan',
        url: '/lesson-planner/plan/:planId/lesson/:id/schedule/:schedId',
      },
    ],
  };

  constructor(
    private router: Router,
    private _location: Location,
    iconRegistry: MatIconRegistry,
    private ls: LocalStorageService,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'home',
      sanitizer.bypassSecurityTrustHtml(HOME_ICON)
    );
    iconRegistry.addSvgIconLiteral(
      'term',
      sanitizer.bypassSecurityTrustHtml(TERM_ICON)
    );
    iconRegistry.addSvgIconLiteral(
      'lesson_plan',
      sanitizer.bypassSecurityTrustHtml(LESSON_PLAN_ICON)
    );
    iconRegistry.addSvgIconLiteral(
      'lesson_info',
      sanitizer.bypassSecurityTrustHtml(LESSON_INFO_ICON)
    );
    iconRegistry.addSvgIconLiteral(
      'profile',
      sanitizer.bypassSecurityTrustHtml(PROFILE_ICON)
    );
    iconRegistry.addSvgIconLiteral(
      'library',
      sanitizer.bypassSecurityTrustHtml(LIBRARY_ICON)
    );

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (Object.values(event.snapshot.data).length > 0) {
          if (event.snapshot.data['breadcrumb']) {
            this.breadcrumbs = [];
            this.current = event.snapshot.data['breadcrumb'];
            this.schedId = event.snapshot.params['schedid'];
            this.planId = event.snapshot.params['planid'];
            this.id = event.snapshot.params['id'];

            this.default_breadcrumbs[this.current.mode as keyof Breadcrumbs] &&
              this.default_breadcrumbs[
                this.current.mode as keyof Breadcrumbs
              ].forEach((val) => {
                let url = val.url;
                url = url.replace(':schedId', this.schedId);
                url = url.replace(':id', this.id);
                url = url.replace(':planId', this.planId);

                this.breadcrumbs.push({
                  label: val.label,
                  url: url,
                });
              });

            if (event.snapshot.queryParams['new']) {
              this.newlyCreated = true;
            }
          } else {
            this.current = {
              mode: undefined,
              step: undefined,
            };
          }
        }
      }
    });
  }

  goBackUrl(): void {
    console.log('this.newlyCreated', this.newlyCreated);
    console.log('this.current', this.current);
    if (this.newlyCreated) {
      if (this.current.step === 1) {
        switch (this.current.mode) {
          case 'new_lesson':
          case 'existing_lesson':
            this.router.navigate([`/lesson-planner/lessons`]);
            break;
          case 'new_term':
          case 'existing_term':
            this.router.navigate([`/lesson-planner/plans`]);
            break;
          default:
            break;
        }

        this.newlyCreated = false;
        return;
      }

      this.router.navigate([`${this.breadcrumbs[0].url}`]);
      return;
    }

    this._location.back();
  }
}
